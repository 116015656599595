<template>
  <div class="content content--home">
    <h1 class="title title--big title--theme title--indent">Изменение задачи</h1>
    <form @submit.prevent="onCheckForm">
      <app-grid items="1" col="1">
        <template #item-1>
          <app-grid>
            <template #item-1>
              <app-form-group label-for="name" label="Название" required>
                <app-input
                  v-model.trim="form.name"
                  id="name"
                  placeholder="Введите Название"
                  :error="$v.form.name.$error"
                  @change.native="$v.form.name.$touch()"
                />
                <template #error>
                  <div v-if="$v.form.name.$dirty && !$v.form.name.required">Обязательное поле</div>
                </template>
              </app-form-group>
              <app-form-group label-for="desc" label="Описание" required>
                <vue-editor v-model="form.desc" :editor-toolbar="customToolbar" class="editor" />
                <template #error>
                  <div v-if="$v.form.desc.$dirty && !$v.form.desc.required">Обязательное поле</div>
                </template>
              </app-form-group>
              <app-form-group label-for="executor" label="Исполнитель">
                <AppSelect
                  v-model="form.executor"
                  :options="user_options"
                  :reduce="user => user.id"
                  :error="$v.form.executor.$error"
                  @change.native="$v.form.executor.$touch()"
                  :filterable="true"
                  :searchable="true"
                  :clearable="true"
                  multiple
                  label="id"
                  placeholder="Выберите участника"
                  class="select select--multiple"
                >
                  <template #open-indicator>
                    <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                      <path
                        d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                        fill="#fff"></path>
                    </svg>
                  </template>
                  <template #no-options>Ничего не найдено</template>
                  <template #option="option">
                    <div class="select__item d-center">{{ option.full_name }}</div>
                  </template>
                  <template #selected-option="option">
                    <div class="selected d-center">{{ option.full_name }}</div>
                  </template>
                </AppSelect>

              </app-form-group>
              <app-form-group label-for="co_executor" label="Соисполнитель">
                <AppSelect
                  v-model="form.co_executor"
                  :options="user_options"
                  :reduce="user => user.id"
                  :filterable="true"
                  :searchable="true"
                  :clearable="true"
                  multiple
                  label="id"
                  placeholder="Выберите участника"
                  class="select select--multiple"
                >
                  <template #open-indicator>
                    <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                      <path
                        d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                        fill="#fff"></path>
                    </svg>
                  </template>
                  <template #no-options>Ничего не найдено</template>
                  <template #option="option">
                    <div class="select__item d-center">{{ option.full_name }}</div>
                  </template>
                  <template #selected-option="option">
                    <div class="selected d-center">{{ option.full_name }}</div>
                  </template>
                </AppSelect>
              </app-form-group>
              <app-form-group label-for="observer" label="Наблюдатель">
                <AppSelect
                  v-model="form.observer"
                  :options="user_options"
                  :reduce="user => user.id"
                  :filterable="true"
                  :searchable="true"
                  :clearable="true"
                  multiple
                  label="id"
                  placeholder="Выберите участника"
                  class="select select--multiple"
                >
                  <template #open-indicator>
                    <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                      <path
                        d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                        fill="#fff"></path>
                    </svg>
                  </template>
                  <template #no-options>Ничего не найдено</template>
                  <template #option="option">
                    <div class="select__item d-center">{{ option.full_name }}</div>
                  </template>
                  <template #selected-option="option">
                    <div class="selected d-center">{{ option.full_name }}</div>
                  </template>
                </AppSelect>
              </app-form-group>
            </template>
            <template #item-2>
              <app-form-group label-for="start_date" label="Дата начала">
                <app-input
                  @paste.native.prevent
                  v-model="form.start_date"
                  v-mask="'99.99.9999 99:99'"
                  id="start_date"
                  autocomplete="off"
                />
              </app-form-group>
              <app-form-group label-for="deadline" label="Крайний срок">
                <app-input
                  @paste.native.prevent
                  @input.native="$v.form.deadline.$touch"
                  v-model="form.deadline"
                  v-mask="'99.99.9999 99:99'"
                  id="deadline"
                  autocomplete="off"
                  :error="$v.form.deadline.$error"
                />
                <template #error>
                  <div v-if="$v.form.deadline.$dirty && !$v.form.deadline.underscorePresent">Заполните поле полностью</div>
                  <div v-if="$v.form.deadline.$dirty && !$v.form.deadline.required">Обязательное поле</div>
                </template>
              </app-form-group>
              <app-form-group label="Файлы">
                <AppMultiUploader
                  v-model="form.docs"
                  :limit="10"
                />
              </app-form-group>
            </template>
          </app-grid>
        </template>
      </app-grid>
      <app-cells>
        <app-button :disabled="$v.form.$error" ref="submit">Редактировать задачу</app-button>
      </app-cells>
    </form>
  </div>
</template>

<script>
  import { required } from 'vuelidate/lib/validators'
  import { VueEditor } from 'vue2-editor'
  const underscorePresent = value => {
    if (!value) return true
    else return !value.includes('_')
  }

  export default {
    name: 'EditTask',
    components: { VueEditor },
    data() {
      return {
        form: {
          name: null,
          desc: null,
          start_date: null,
          deadline: null,
          docs: [],
          executor: [],
          co_executor: [],
          observer: [],
        },
        user_options: [],
        customToolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link'], ['clean']
        ]

      }
    },
    validations: {
      form: {
        name: { required },
        desc: { required },
        deadline: { required, underscorePresent },
        executor: { required },
      },
    },
    created() {
      this.getData()
      this.getUsers()
    },
    methods: {
      getUsers() {
        this.$store.dispatch('users/GET_DATA')
          .then(response => {
            this.user_options = response.data
          })
      },
      getData() {
        this.$store.dispatch('profile/task/GET_DATA', this.$route.params.id)
          .then((response) => {
            this.form = response.data
            this.docs = response.data.docs
          })
      },
      onCheckForm() {
        this.$v.form.$touch()
        if (this.$v.form.$invalid) {
          this.$notify({
            type: 'warn',
            title: 'Внимание!',
            text: 'Проверьте правильность заполнения полей формы.'
          })
        } else {
          // this.form.docs = []
          // this.form.docs.map(item => this.form.docs.push(item.id))
          this.sendForm()
        }
      },
      sendForm() {
        this.$refs.submit.preload = true
        const id = this.$route.params.id;
        this.$store.dispatch('profile/task/PUT_DATA', this.normalizeForm())
          .then(() => {
            this.$refs.submit.preload = false
            this.$notify({
              type: 'success',
              title: 'Успех!',
              text: 'Пользователь добавлен'
            })
            this.$router.push({ name: 'task', params: {id} })
          })
          .catch(error => {
            this.$refs.submit.preload = false
            for (const key in error.response.data) {
              if (typeof error.response.data[key] === 'string') {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: error.response.data[key]
                })
              } else {
                this.$notify({
                  type: 'error',
                  title: 'Внимание!',
                  text: error.response.data[key][0]
                })
              }
            }
          })
      },
      normalizeForm() {
        const { ...normalizedForm } = this.form
        normalizedForm.docs = normalizedForm.docs.map(item => item.id)
        normalizedForm.status = normalizedForm.status.id
        normalizedForm.executor = normalizedForm.executor.map(item => item.id)
        normalizedForm.co_executor = normalizedForm.co_executor.map(item => item.id)
        normalizedForm.observer = normalizedForm.observer.map(item => item.id)
        normalizedForm.todo = normalizedForm.todo.map(item => item.id)
        return normalizedForm
      },
    }
  }
</script>
